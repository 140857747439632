import React from 'react';
import MainHome from '../components/MainHome/MainHome';
import BottomPubli from '../components/BottomPubli/BottomPubli';

const Home = () => {
  return (
    <div>
      <MainHome />
      <BottomPubli/>
    </div>
  );
};

export default Home;
