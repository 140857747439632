import './BottomPubli.css'

const BottomPubli = () => {
  return (
    <div className='bottomPubli'>
        <div className='bottomPubli1'>
          <div className='links-bottom-publi'>
            <a
                href="https://wa.me/5522992167939?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20parcerias%20e%2Fou%20servi%C3%A7os%20GMIDI">
                <h3 id='bottomPubliTitle'>Anuncie aqui</h3>
            </a>
            <a
                href="https://wa.me/5522992167939?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20parcerias%20e%2Fou%20servi%C3%A7os%20GMIDI">
                <h3 id='bottomPubliTitle'>Anuncie aqui</h3>
            </a>
            <a
                href="https://wa.me/5522992167939?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20parcerias%20e%2Fou%20servi%C3%A7os%20GMIDI">
                <h3 id='bottomPubliTitle'>Anuncie aqui</h3>
            </a>
          </div>
        </div>
        <div className='bottomPubli2'>
            <button className='closeBottomPubli'>Publicidade</button>
        </div>
        <div className='bottomPubli3'></div>
    </div>
  )
}

export default BottomPubli