import { useState } from 'react'
import './CenterPubli.css'

const CenterPubli = () => {
  const [isVisible, setIsVisible] = useState(true)

  const handleClose = () => {
    setIsVisible(false)
  }

  return (
    <div className='centerPubli' style={{display: isVisible ? 'block': 'none'}}>
        <button className='closeCenterPubli' id='closeCenterPubli' onClick={handleClose}>x</button>
          <a
            href="https://wa.me/5522992167939?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20parcerias%20e%2Fou%20servi%C3%A7os%20GMIDI">
            <h3 id='centerPubliTitle'>Anuncie aqui</h3>
          </a>
    </div>
  )
}

export default CenterPubli