import React from 'react';
import AllProducts from '../components/AllProducts/AllProducts';
import BottomPubli from '../components/BottomPubli/BottomPubli';

const Products = () => {
  return (
    <div>
      <AllProducts />

      <BottomPubli/>
    </div>
  );
};

export default Products;
