import React from 'react';
import AllGames from '../components/AllGames/AllGames';
import BottomPubli from '../components/BottomPubli/BottomPubli';

const Games = () => {
  return (
    <div>
      <AllGames />
      <BottomPubli/>
    </div>
  );
};

export default Games;
