import EmailWhatsapp from '../components/EmailWhatsapp/EmailWhatsapp';
import BottomPubli from '../components/BottomPubli/BottomPubli';
import CenterPubli from '../components/CenterPubli/CenterPubli';

const Contact = () => {
  return (
    <div>
      <CenterPubli/>
      <EmailWhatsapp />
      <BottomPubli/>
    </div>
  );
};

export default Contact;
